<template>
  <div>
    <div style="margin-top: 10px" class="item divider">
      <span class="label">
        <span class="required">*</span>
        联系人
      </span>
      <input
        class="input"
        v-model="contact"
        type="text"
        placeholder="请输入联系人姓名"
      />
    </div>
    <div class="item">
      <span class="label">
        <span class="required">*</span>
        手机号码
      </span>
      <input
        class="input"
        v-model="phone"
        type="tel"
        placeholder="请输入联系人手机号码"
        maxlength="11"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
    </div>
    <div style="margin-top: 10px" class="item divider">
      <span class="label"> 企业名称 </span>
      <input
        class="input"
        v-model="name"
        type="text"
        placeholder="请输入企业名称"
      />
    </div>
    <div class="item divider">
      <span class="label">
        <span class="required">*</span>
        所在城市
      </span>
      <input
        class="input"
        v-model="city"
        type="text"
        placeholder="请输入所在城市"
      />
    </div>
    <div class="item divider">
      <span class="label">
        <span class="required">*</span>
        预计人数
      </span>
      <input
        class="input"
        v-model="projectedNumbers"
        type="number"
        placeholder="请输入预计申请的人数"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
    </div>
    <div class="item divider">
      <span class="label">
        <span class="required">*</span>
        人均预算
      </span>
      <input
        class="input"
        v-model="budgetPerCapita"
        readonly
        type="text"
        placeholder="请选择人均预算"
        @click="showPicker = true"
      />
    </div>
    <div class="item" v-if="$store.state.hospitalId === 'HS2022120788'">
      <span class="label"> 推荐人 </span>
      <input
        class="input"
        v-model="recommendPerson"
        type="text"
        placeholder="请输入推荐人"
      />
    </div>
    <div style="margin-top: 10px" class="item">
      <span class="label"> 其他需求 </span>
      <input
        class="input"
        v-model="remark"
        type="text"
        placeholder="请输入其他的需求，选填"
      />
    </div>

    <van-popup
      v-model="showPicker"
      round
      position="bottom"
      safe-area-inset-bottom
    >
      <van-picker
        show-toolbar
        title="请选择"
        :columns="columns"
        visible-item-count="5"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>

    <div class="submit-button" @click="submit">提交</div>
  </div>
</template>

<script>
import _ from "lodash";
import { getBudDictData, applyGroupInfo } from "@/api/index";
export default {
  data() {
    return {
      contact: "",
      phone: "",
      name: "",
      city: "",
      projectedNumbers: "",
      budgetPerCapita: "",
      remark: "",
      value: "",
      showPicker: false,
      columns: [],
      recommendPerson: "",
    };
  },
  created() {
    this.getDictData();
  },
  mounted() {
    this.$setTitle("企业服务");
    if (localStorage.getItem("hospitalId") != this.$route.query.hospitalId) {
      localStorage.setItem("token", "");
      localStorage.setItem("telNo", "");
      localStorage.removeItem("hospitalInfo");
    }
    this.$store.commit(
      "setHospitalId",
      this.$route.query.hospitalId || localStorage.getItem("hospitalId")
    );
  },
  methods: {
    async getDictData() {
      const res = await getBudDictData();
      this.columns = res;
    },
    onConfirm(value) {
      this.budgetPerCapita = value;
      this.showPicker = false;
    },
    //提交
    submit: _.debounce(async function () {
      if (!this.vilidate()) return;
      const productId =
        this.$store.state.fromPage === "group-demo"
          ? this.$store.state.viewProductId
          : null;
      const res = await applyGroupInfo({
        hospitalCode: this.$route.query.hospitalId,
        contact: this.contact,
        phone: this.phone,
        name: this.name,
        city: this.city,
        projectedNumbers: this.projectedNumbers,
        budgetPerCapita: this.budgetPerCapita,
        remark: this.remark,
        productId,
        recommendPerson: this.recommendPerson,
      });
      this.$router.replace("group-apply-status");
    }, 300),
    vilidate() {
      if (!this.contact) {
        this.$toast("请输入联系人姓名");
        return false;
      } else if (!this.phone || this.phone.length !== 11) {
        this.$toast("请输入正确的手机号码");
        return false;
      } else if (!this.city) {
        this.$toast("请输入所在城市名");
        return false;
      } else if (!this.projectedNumbers) {
        this.$toast("请输入预计申请的人数");
        return false;
      } else if (!this.budgetPerCapita) {
        this.$toast("请选择人均预算");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style>
html body {
  background-color: #f4f4f4;
}
.van-picker__confirm {
  color: #00c4b3;
}
</style>

<style scoped>
.item {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 15px;
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #2c2f37;
  background-color: #fff;
}

.divider::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #edeff0;
  transform: scaleY(0.5);
}

.label {
  display: flex;
  width: 100px;
}

.required {
  font-size: 15px;
  color: #ff3d00;
}

.input {
  width: 100%;
  font-size: 15px;
}

.input::placeholder {
  font-size: 15px;
  color: #ccc;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 20px;
  border-radius: 20px;
  background-color: #00c4b3;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}
</style>
